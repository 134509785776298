/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MinimalUser = {
    id: number;
    title: string | null;
    gender: MinimalUser.gender;
    firstName: string;
    lastName: string;
    email: string;
    image: string | null;
    role: MinimalUser.role;
};
export namespace MinimalUser {
    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
        DIVERSE = 'diverse',
        NOT_SET = 'not_set',
        DELETED = 'deleted',
    }
    export enum role {
        THERAPIST = 'therapist',
        USER = 'user',
    }
}

