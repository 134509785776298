/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { AuthService } from './services/AuthService';
import { BookingsService } from './services/BookingsService';
import { ClientsService } from './services/ClientsService';
import { EventCategoriesService } from './services/EventCategoriesService';
import { EventsService } from './services/EventsService';
import { HealthService } from './services/HealthService';
import { NotificationsService } from './services/NotificationsService';
import { TherapistsService } from './services/TherapistsService';
import { WhoAmIService } from './services/WhoAmIService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class TebutoApi {
    public readonly auth: AuthService;
    public readonly bookings: BookingsService;
    public readonly clients: ClientsService;
    public readonly eventCategories: EventCategoriesService;
    public readonly events: EventsService;
    public readonly health: HealthService;
    public readonly notifications: NotificationsService;
    public readonly therapists: TherapistsService;
    public readonly whoAmI: WhoAmIService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'https://clients.api.tebuto.local',
            VERSION: config?.VERSION ?? '1.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.auth = new AuthService(this.request);
        this.bookings = new BookingsService(this.request);
        this.clients = new ClientsService(this.request);
        this.eventCategories = new EventCategoriesService(this.request);
        this.events = new EventsService(this.request);
        this.health = new HealthService(this.request);
        this.notifications = new NotificationsService(this.request);
        this.therapists = new TherapistsService(this.request);
        this.whoAmI = new WhoAmIService(this.request);
    }
}

