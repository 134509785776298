/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CodeLoginPayload } from '../models/CodeLoginPayload';
import type { RequestLoginCodePayload } from '../models/RequestLoginCodePayload';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AuthService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Request Login Code
     * Request Login Code
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public requestLoginCode(
        requestBody: RequestLoginCodePayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/request-login-code',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Login via Code
     * Login via Code
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public login(
        requestBody: CodeLoginPayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Logout
     * Logout endpoint
     * @returns any
     * @throws ApiError
     */
    public logout(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/logout',
        });
    }
}
