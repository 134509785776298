/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MinimalBooking = {
    id: number;
    locationSelection: MinimalBooking.locationSelection;
    isConfirmed: boolean;
    isOutage: boolean;
};
export namespace MinimalBooking {
    export enum locationSelection {
        VIRTUAL = 'virtual',
        ONSITE = 'onsite',
    }
}

