/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SelectPlanPreconditionPayload = {
    selection: SelectPlanPreconditionPayload.selection;
    type: SelectPlanPreconditionPayload.type;
};
export namespace SelectPlanPreconditionPayload {
    export enum selection {
        FREE = 'free',
        PAID = 'paid',
    }
    export enum type {
        SELECT_PLAN = 'select-plan',
        CONFIGURATION = 'configuration',
        BILLING = 'billing',
        OPENINGS = 'openings',
        WAITLIST = 'waitlist',
    }
}

