/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Contract = {
    endDate: string | null;
    plan: Contract.plan;
    renew: boolean;
};
export namespace Contract {
    export enum plan {
        FREE = 'free',
        PAID = 'paid',
    }
}

