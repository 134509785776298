/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileWithUrl } from '../models/FileWithUrl';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FilesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Upload a file
     * Upload File
     * @param therapistId
     * @param formData
     * @returns FileWithUrl
     * @throws ApiError
     */
    public uploadFile(
        therapistId: number,
        formData: {
            type?: 'logo' | 'attachment';
            scope?: 'public-read' | 'private' | 'public-read-write' | 'authenticated-read' | 'aws-exec-read' | 'bucket-owner-read' | 'bucket-owner-full-control';
            file?: Blob;
        },
    ): CancelablePromise<FileWithUrl> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/files',
            path: {
                'therapistId': therapistId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Get a file by its key
     * Get File
     * @param therapistId
     * @param fileIdentifier
     * @returns FileWithUrl
     * @throws ApiError
     */
    public getFile(
        therapistId: number,
        fileIdentifier: string,
    ): CancelablePromise<FileWithUrl> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/files/{fileIdentifier}',
            path: {
                'therapistId': therapistId,
                'fileIdentifier': fileIdentifier,
            },
        });
    }
    /**
     * Delete a file by its key
     * Delete File
     * @param therapistId
     * @param fileIdentifier
     * @returns void
     * @throws ApiError
     */
    public deleteFile(
        therapistId: number,
        fileIdentifier: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/therapists/{therapistId}/files/{fileIdentifier}',
            path: {
                'therapistId': therapistId,
                'fileIdentifier': fileIdentifier,
            },
        });
    }
}
