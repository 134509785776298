/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookEventForCustomerPayload } from '../models/BookEventForCustomerPayload';
import type { Booking } from '../models/Booking';
import type { CancelBookingByTherapistPayload } from '../models/CancelBookingByTherapistPayload';
import type { MinimalBooking } from '../models/MinimalBooking';
import type { RejectBookingPayload } from '../models/RejectBookingPayload';
import type { SetOutageFeeAppliesPayload } from '../models/SetOutageFeeAppliesPayload';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class BookingsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get bookings of a therapist
     * Get Bookings
     * @param therapistId
     * @param status Event / Booking status to filter for. Optional
     * @param outageFeeApplies Outage fee applies status to filter for. Optional
     * @param start Earliest start date to filter for. Optional
     * @param end Latest end date to filter for. Optional
     * @returns Booking
     * @throws ApiError
     */
    public getBookings(
        therapistId: number,
        status?: 'open' | 'booked' | 'approved' | 'cancelled' | 'rejected' | 'outage' | 'reserved',
        outageFeeApplies?: 'yes' | 'no' | 'not-selected',
        start?: string,
        end?: string,
    ): CancelablePromise<Array<Booking>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/bookings',
            path: {
                'therapistId': therapistId,
            },
            query: {
                'status': status,
                'outageFeeApplies': outageFeeApplies,
                'start': start,
                'end': end,
            },
        });
    }
    /**
     * Book an event for a customer
     * Book an event for a customer
     * @param therapistId
     * @param requestBody
     * @returns MinimalBooking
     * @throws ApiError
     */
    public bookEventForCustomer(
        therapistId: number,
        requestBody: BookEventForCustomerPayload,
    ): CancelablePromise<MinimalBooking> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/bookings',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Confirm a booking
     * Confirm a booking
     * @param therapistId
     * @param bookingId
     * @returns MinimalBooking
     * @throws ApiError
     */
    public confirmBooking(
        therapistId: number,
        bookingId: number,
    ): CancelablePromise<MinimalBooking> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/bookings/{bookingId}/confirm',
            path: {
                'therapistId': therapistId,
                'bookingId': bookingId,
            },
        });
    }
    /**
     * Reject a booking
     * Reject a booking
     * @param therapistId
     * @param bookingId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public rejectBooking(
        therapistId: number,
        bookingId: number,
        requestBody: RejectBookingPayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/bookings/{bookingId}/reject',
            path: {
                'therapistId': therapistId,
                'bookingId': bookingId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Cancel a booking
     * Cancel a booking
     * @param therapistId
     * @param bookingId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public cancelBooking(
        therapistId: number,
        bookingId: number,
        requestBody: CancelBookingByTherapistPayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/bookings/{bookingId}/cancel',
            path: {
                'therapistId': therapistId,
                'bookingId': bookingId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Mark a booking as an outage
     * Mark a booking as an outage
     * @param therapistId
     * @param bookingId
     * @returns any
     * @throws ApiError
     */
    public markAsOutage(
        therapistId: number,
        bookingId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/bookings/{bookingId}/mark-as-outage',
            path: {
                'therapistId': therapistId,
                'bookingId': bookingId,
            },
        });
    }
    /**
     * Select if an outage fee applies
     * Select if an outage fee applies
     * @param therapistId
     * @param bookingId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public setOutageFeeApplies(
        therapistId: number,
        bookingId: number,
        requestBody: SetOutageFeeAppliesPayload,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/bookings/{bookingId}/set-outage-fee-applies',
            path: {
                'therapistId': therapistId,
                'bookingId': bookingId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
