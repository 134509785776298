/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { AddressService } from './services/AddressService';
import { AuthService } from './services/AuthService';
import { BookingsService } from './services/BookingsService';
import { CalendarsService } from './services/CalendarsService';
import { CharacteristicsService } from './services/CharacteristicsService';
import { ClientsService } from './services/ClientsService';
import { ContactInformationService } from './services/ContactInformationService';
import { EventCategoriesService } from './services/EventCategoriesService';
import { EventRulesService } from './services/EventRulesService';
import { EventsService } from './services/EventsService';
import { FeaturesService } from './services/FeaturesService';
import { FilesService } from './services/FilesService';
import { HealthService } from './services/HealthService';
import { LanguagesService } from './services/LanguagesService';
import { NotificationsService } from './services/NotificationsService';
import { OpeningsService } from './services/OpeningsService';
import { PaymentService } from './services/PaymentService';
import { PlacesService } from './services/PlacesService';
import { PreconditionsService } from './services/PreconditionsService';
import { SettingsService } from './services/SettingsService';
import { TherapistsService } from './services/TherapistsService';
import { ThirdPartyAccountsService } from './services/ThirdPartyAccountsService';
import { UsersService } from './services/UsersService';
import { WhoAmIService } from './services/WhoAmIService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class TebutoApi {
    public readonly address: AddressService;
    public readonly auth: AuthService;
    public readonly bookings: BookingsService;
    public readonly calendars: CalendarsService;
    public readonly characteristics: CharacteristicsService;
    public readonly clients: ClientsService;
    public readonly contactInformation: ContactInformationService;
    public readonly eventCategories: EventCategoriesService;
    public readonly eventRules: EventRulesService;
    public readonly events: EventsService;
    public readonly features: FeaturesService;
    public readonly files: FilesService;
    public readonly health: HealthService;
    public readonly languages: LanguagesService;
    public readonly notifications: NotificationsService;
    public readonly openings: OpeningsService;
    public readonly payment: PaymentService;
    public readonly places: PlacesService;
    public readonly preconditions: PreconditionsService;
    public readonly settings: SettingsService;
    public readonly therapists: TherapistsService;
    public readonly thirdPartyAccounts: ThirdPartyAccountsService;
    public readonly users: UsersService;
    public readonly whoAmI: WhoAmIService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'https://therapists.api.tebuto.local',
            VERSION: config?.VERSION ?? '1.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.address = new AddressService(this.request);
        this.auth = new AuthService(this.request);
        this.bookings = new BookingsService(this.request);
        this.calendars = new CalendarsService(this.request);
        this.characteristics = new CharacteristicsService(this.request);
        this.clients = new ClientsService(this.request);
        this.contactInformation = new ContactInformationService(this.request);
        this.eventCategories = new EventCategoriesService(this.request);
        this.eventRules = new EventRulesService(this.request);
        this.events = new EventsService(this.request);
        this.features = new FeaturesService(this.request);
        this.files = new FilesService(this.request);
        this.health = new HealthService(this.request);
        this.languages = new LanguagesService(this.request);
        this.notifications = new NotificationsService(this.request);
        this.openings = new OpeningsService(this.request);
        this.payment = new PaymentService(this.request);
        this.places = new PlacesService(this.request);
        this.preconditions = new PreconditionsService(this.request);
        this.settings = new SettingsService(this.request);
        this.therapists = new TherapistsService(this.request);
        this.thirdPartyAccounts = new ThirdPartyAccountsService(this.request);
        this.users = new UsersService(this.request);
        this.whoAmI = new WhoAmIService(this.request);
    }
}

