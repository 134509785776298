/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DateTime } from './DateTime';
import type { TherapistEntity } from './TherapistEntity';
export type FileEntity = {
    id: number;
    identifier: string;
    name: string;
    type: FileEntity.type;
    scope: FileEntity.scope;
    bucket: string;
    region: string;
    createdAt: DateTime;
    therapistId: number;
    therapist: TherapistEntity;
};
export namespace FileEntity {
    export enum type {
        LOGO = 'logo',
        ATTACHMENT = 'attachment',
    }
    export enum scope {
        PUBLIC_READ = 'public-read',
        PRIVATE = 'private',
        PUBLIC_READ_WRITE = 'public-read-write',
        AUTHENTICATED_READ = 'authenticated-read',
        AWS_EXEC_READ = 'aws-exec-read',
        BUCKET_OWNER_READ = 'bucket-owner-read',
        BUCKET_OWNER_FULL_CONTROL = 'bucket-owner-full-control',
    }
}

