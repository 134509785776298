/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TherapistEntity } from './TherapistEntity';
export type TherapistInformationOptionEntity = {
    id: number;
    slug: string;
    name: string;
    type: TherapistInformationOptionEntity.type;
    therapists: Array<TherapistEntity>;
};
export namespace TherapistInformationOptionEntity {
    export enum type {
        CORNER_STONE = 'cornerStone',
        EXCLUDED_CORNER_STONE = 'excludedCornerStone',
        PROCEDURE = 'procedure',
        SERVICE = 'service',
        TRAINING_AND_ADMISSION = 'trainingAndAdmission',
        BILLING = 'billing',
    }
}

