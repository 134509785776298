/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Calendar } from './Calendar';
import type { MinimalUser } from './MinimalUser';
export type CalendarAccount = {
    id: number;
    name: string;
    provider: CalendarAccount.provider;
    calendars: Array<Calendar>;
    user: MinimalUser;
};
export namespace CalendarAccount {
    export enum provider {
        GOOGLE = 'google',
        MICROSOFT = 'microsoft',
    }
}

