/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Health = {
    status: Health.status;
    version: string;
    commit: string;
    reason?: string;
};
export namespace Health {
    export enum status {
        UP = 'UP',
        DOWN = 'DOWN',
    }
}

