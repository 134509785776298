/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdateCharacteristicPayload = {
    category: UpdateCharacteristicPayload.category;
    selectedCharacteristicIds: Array<number>;
};
export namespace UpdateCharacteristicPayload {
    export enum category {
        ADMISSIONS = 'admissions',
        PROCEDURES = 'procedures',
        BILLING = 'billing',
        SERVICES = 'services',
        CORNERSTONES = 'cornerstones',
        EXCLUDED_CORNERSTONES = 'excludedCornerstones',
    }
}

