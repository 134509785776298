/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Opening } from '../models/Opening';
import type { UpdateOpeningsPayload } from '../models/UpdateOpeningsPayload';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class OpeningsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all openings
     * Get all openings
     * @param therapistId
     * @returns Opening
     * @throws ApiError
     */
    public getAllOpenings(
        therapistId: number,
    ): CancelablePromise<Array<Opening>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/openings',
            path: {
                'therapistId': therapistId,
            },
        });
    }
    /**
     * Update the openings of a therapist
     * Update Openings
     * @param therapistId
     * @param requestBody
     * @returns Opening
     * @throws ApiError
     */
    public updateOpenings(
        therapistId: number,
        requestBody: UpdateOpeningsPayload,
    ): CancelablePromise<Array<Opening>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/therapists/{therapistId}/openings',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
