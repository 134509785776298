/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ToggleClientNotification = {
    enabled: boolean;
    channel: ToggleClientNotification.channel;
};
export namespace ToggleClientNotification {
    export enum channel {
        EMAIL = 'email',
    }
}

