/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type EventRulePayload = {
    timeStart: string;
    dateStart: string;
    dateEnd: string | null;
    day: EventRulePayload.day;
    interval: number;
    categoryId: number;
};
export namespace EventRulePayload {
    export enum day {
        MONDAY = 'monday',
        TUESDAY = 'tuesday',
        WEDNESDAY = 'wednesday',
        THURSDAY = 'thursday',
        FRIDAY = 'friday',
        SATURDAY = 'saturday',
        SUNDAY = 'sunday',
    }
}

