import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface TherapistContext {
    id: number | null
}

const initialState: TherapistContext = {
    id: null
}

export const therapistContext = createSlice({
    name: 'therapistContext',
    initialState: initialState,
    reducers: {
        setTherapistId: (state, action: PayloadAction<number>) => {
            state.id = action.payload
        },
        resetTherapistId: state => {
            state.id = null
        }
    }
})

export const { setTherapistId, resetTherapistId } = therapistContext.actions
export default therapistContext.reducer
