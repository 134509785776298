/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Calendar } from '../models/Calendar';
import type { CalendarAccount } from '../models/CalendarAccount';
import type { UpdateCalendarPayload } from '../models/UpdateCalendarPayload';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CalendarsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Third Party Calendar Accounts
     * Get Calendar Accounts
     * @param therapistId
     * @returns CalendarAccount
     * @throws ApiError
     */
    public getCalendarAccounts(
        therapistId: number,
    ): CancelablePromise<Array<CalendarAccount>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/calendar-accounts',
            path: {
                'therapistId': therapistId,
            },
        });
    }
    /**
     * Synchronize Calendars
     * Synchronize Calendars
     * @param therapistId
     * @param accountId
     * @returns CalendarAccount
     * @throws ApiError
     */
    public synchronizeCalendars(
        therapistId: number,
        accountId: number,
    ): CancelablePromise<CalendarAccount> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/calendar-accounts/{accountId}/synchronize',
            path: {
                'therapistId': therapistId,
                'accountId': accountId,
            },
        });
    }
    /**
     * Update Calendar
     * Update Calendar
     * @param therapistId
     * @param accountId
     * @param calendarId
     * @param requestBody
     * @returns Calendar
     * @throws ApiError
     */
    public updateCalendar(
        therapistId: number,
        accountId: number,
        calendarId: number,
        requestBody: UpdateCalendarPayload,
    ): CancelablePromise<Calendar> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/therapists/{therapistId}/calendar-accounts/{accountId}/calendars/{calendarId}',
            path: {
                'therapistId': therapistId,
                'accountId': accountId,
                'calendarId': calendarId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
