/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdateOwnClientPayload = {
    gender: UpdateOwnClientPayload.gender;
    firstName: string;
    lastName: string;
};
export namespace UpdateOwnClientPayload {
    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
        DIVERSE = 'diverse',
        NOT_SET = 'not_set',
        DELETED = 'deleted',
    }
}

