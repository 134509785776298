/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DateTime } from './DateTime';
import type { TherapistEntity } from './TherapistEntity';
export type TherapistPreconditionEntity = {
    id: number;
    type: TherapistPreconditionEntity.type;
    version: string;
    fulfilledAt: Record<string, any> | null;
    createdAt: DateTime;
    therapist: TherapistEntity;
};
export namespace TherapistPreconditionEntity {
    export enum type {
        SELECT_PLAN = 'select-plan',
        CONFIGURATION = 'configuration',
        BILLING = 'billing',
        OPENINGS = 'openings',
        WAITLIST = 'waitlist',
    }
}

