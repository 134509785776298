/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TherapistCharacteristic = {
    id: number;
    key: string;
    label: string;
    category: TherapistCharacteristic.category;
};
export namespace TherapistCharacteristic {
    export enum category {
        ADMISSIONS = 'admissions',
        PROCEDURES = 'procedures',
        BILLING = 'billing',
        SERVICES = 'services',
        CORNERSTONES = 'cornerstones',
        EXCLUDED_CORNERSTONES = 'excludedCornerstones',
    }
}

