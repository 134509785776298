/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type EventRule = {
    timeStart: string;
    dateStart: string;
    dateEnd: string | null;
    id: number;
    day: EventRule.day;
    interval: number;
    categoryId: number;
};
export namespace EventRule {
    export enum day {
        MONDAY = 'monday',
        TUESDAY = 'tuesday',
        WEDNESDAY = 'wednesday',
        THURSDAY = 'thursday',
        FRIDAY = 'friday',
        SATURDAY = 'saturday',
        SUNDAY = 'sunday',
    }
}

