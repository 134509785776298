/**
 * Combines multiple class names into a single string.
 * @returns {string} - The combined class names.
 * @example
 * classNames('foo', 'bar', 'baz') // 'foo bar baz'
 * classNames('foo', '', 'bar', undefined, 'baz') // 'foo bar baz'
 */
export function classNames(...classes: string[]): string {
    return classes.filter(Boolean).join(' ')
}

interface RGB {
    r: number
    g: number
    b: number
}

interface IAdjustBrightness {
    hex: string
    percent?: number
}

/**
 * Adjusts a hex color's brightness by a given percentage.
 * Positive percentage increases brightness; negative decreases it.
 * @returns {string} - Adjusted hex color
 */
export function adjustBrightness({ hex, percent = 100 }: IAdjustBrightness): string {
    let { r, g, b } = hexToRgb(hex)

    r = Math.min(255, Math.max(0, r + Math.round((r * percent) / 100)))
    g = Math.min(255, Math.max(0, g + Math.round((g * percent) / 100)))
    b = Math.min(255, Math.max(0, b + Math.round((b * percent) / 100)))

    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`
}

interface IHexToRgba {
    hex: string
    alpha?: number
}

/**
 * Converts a hex color code to an RGBA string with specified opacity.
 * @returns {string} - The RGBA color string.
 */
export function hexToRgba({ hex, alpha = 1 }: IHexToRgba): string {
    const { r, g, b } = hexToRgb(hex)

    return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

interface IBlendWithWhite {
    hex: string
    alpha?: number
    blendWith?: RGB
}

/**
 * Blends a hex color with white based on the specified alpha level.
 * @returns {string} - The hex code of the color blended with white.
 */
export function blendWith({ hex, alpha = 1, blendWith = { r: 255, g: 255, b: 255 } }: IBlendWithWhite): string {
    let { r, g, b } = hexToRgb(hex)

    r = Math.round((1 - alpha) * blendWith.r + alpha * r)
    g = Math.round((1 - alpha) * blendWith.g + alpha * g)
    b = Math.round((1 - alpha) * blendWith.b + alpha * b)

    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
}

/**
 * Determines whether a color is light or dark based on its luminance.
 * @returns {boolean} - True if the color is light; false if it is dark.
 */
export function isColorLight({ hex }: { hex: string }): boolean {
    const { r, g, b } = hexToRgb(hex)

    const brightness = (r * 299 + g * 587 + b * 114) / 1000

    return brightness >= 128
}

/**
 * Gets the luminance of a color.
 * @returns {number} - The luminance of the color. Ranges from 0 to 1.
 */
export function getLuminance({ hex }: { hex: string }): number {
    const { r, g, b } = hexToRgb(hex)

    return (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255
}

/**
 * Converts a hex color code to an RGB object.
 * @returns {object} - An object with the red, green, and blue components.
 */
function hexToRgb(hex: string): RGB {
    const actualHex = hex.replace(/^#/, '')

    const r = Number.parseInt(actualHex.substring(0, 2), 16)
    const g = Number.parseInt(actualHex.substring(2, 4), 16)
    const b = Number.parseInt(actualHex.substring(4, 6), 16)

    return { r, g, b }
}

export function easingCOlors({ hex }: { hex: string }): { bgHex: string; fgHex: string } {
    const calculatedBgColor = blendWith({ hex: hex, alpha: 0.1 })
    const blendedColor = isColorLight({ hex: calculatedBgColor }) ? { r: 0, g: 0, b: 0 } : { r: 255, g: 255, b: 255 }
    const contrastingColor = blendWith({ hex: hex, alpha: getLuminance({ hex: calculatedBgColor }) > 0.99 ? 0 : 0.7, blendWith: blendedColor })

    return { bgHex: calculatedBgColor, fgHex: contrastingColor }
}
