import { City } from '@tebuto/api/public/models/City'
import { MinimalPublicTherapist } from '@tebuto/api/public/models/MinimalPublicTherapist'
import { Contract } from '@tebuto/api/therapists/models/Contract'
import COMPONENT_TEXTS from '@tebuto/translations'

export function getCityLink(city: City) {
    return `/verzeichnis/${city.slug}`
}

export function getTherapistLink(therapist: MinimalPublicTherapist) {
    const cityLink = getCityLink(therapist.address.city)
    return `${cityLink}/${therapist.slug}`
}

export function getPlanLabel(plan: Contract.plan): string {
    switch (plan) {
        case Contract.plan.FREE:
            return COMPONENT_TEXTS.functions.plans.free
        case Contract.plan.PAID:
            return COMPONENT_TEXTS.functions.plans.paid
        default:
            return plan
    }
}
