/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookableEvent } from '../models/BookableEvent';
import type { BookExistingEventPayload } from '../models/BookExistingEventPayload';
import type { BookGeneratedEventPayload } from '../models/BookGeneratedEventPayload';
import type { ClientBooking } from '../models/ClientBooking';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EventsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all bookable events for a client
     * Get all bookable events for a client
     * @param therapistId
     * @param start
     * @param end
     * @returns BookableEvent
     * @throws ApiError
     */
    public getEvents(
        therapistId: number,
        start?: string,
        end?: string,
    ): CancelablePromise<Array<BookableEvent>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/events',
            path: {
                'therapistId': therapistId,
            },
            query: {
                'start': start,
                'end': end,
            },
        });
    }
    /**
     * Book a generated event for a client
     * Book a generated event
     * @param therapistId
     * @param requestBody
     * @returns ClientBooking
     * @throws ApiError
     */
    public bookEvent(
        therapistId: number,
        requestBody: BookGeneratedEventPayload,
    ): CancelablePromise<ClientBooking> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/events',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Book an existing event for a client
     * Book an existing event
     * @param therapistId
     * @param eventId
     * @param requestBody
     * @returns ClientBooking
     * @throws ApiError
     */
    public bookExistingEvent(
        therapistId: number,
        eventId: number,
        requestBody: BookExistingEventPayload,
    ): CancelablePromise<ClientBooking> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/events/{eventId}/book',
            path: {
                'therapistId': therapistId,
                'eventId': eventId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
