/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LanguageOption } from '../models/LanguageOption';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LanguagesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get the Languages of a Therapist
     * Get Languages
     * @param therapistId
     * @returns LanguageOption
     * @throws ApiError
     */
    public getLanguages(
        therapistId: number,
    ): CancelablePromise<Array<LanguageOption>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/languages',
            path: {
                'therapistId': therapistId,
            },
        });
    }
    /**
     * Update the Languages of a Therapist
     * Update Languages
     * @param therapistId
     * @param requestBody
     * @returns LanguageOption
     * @throws ApiError
     */
    public updateLanguages(
        therapistId: number,
        requestBody: Array<LanguageOption>,
    ): CancelablePromise<Array<LanguageOption>> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/therapists/{therapistId}/languages',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
