/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreateCheckoutSessionPayload = {
    plan: CreateCheckoutSessionPayload.plan;
    uiMode: CreateCheckoutSessionPayload.uiMode;
};
export namespace CreateCheckoutSessionPayload {
    export enum plan {
        FREE = 'free',
        PAID = 'paid',
    }
    export enum uiMode {
        HOSTED = 'hosted',
        EMBEDDED = 'embedded',
    }
}

