/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type FileWithUrl = {
    url: string;
    name: string;
    type: FileWithUrl.type;
    scope: FileWithUrl.scope;
};
export namespace FileWithUrl {
    export enum type {
        LOGO = 'logo',
        ATTACHMENT = 'attachment',
    }
    export enum scope {
        PUBLIC_READ = 'public-read',
        PRIVATE = 'private',
        PUBLIC_READ_WRITE = 'public-read-write',
        AUTHENTICATED_READ = 'authenticated-read',
        AWS_EXEC_READ = 'aws-exec-read',
        BUCKET_OWNER_READ = 'bucket-owner-read',
        BUCKET_OWNER_FULL_CONTROL = 'bucket-owner-full-control',
    }
}

