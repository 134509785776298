/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DateTime } from './DateTime';
import type { EventCategoryEntity } from './EventCategoryEntity';
import type { EventEntity } from './EventEntity';
export type EventRuleEntity = {
    id: number;
    timeStart: DateTime;
    dateStart: DateTime;
    dateEnd: Record<string, any> | null;
    day: EventRuleEntity.day;
    interval: number;
    category: EventCategoryEntity;
    events: Array<EventEntity>;
};
export namespace EventRuleEntity {
    export enum day {
        MONDAY = 'monday',
        TUESDAY = 'tuesday',
        WEDNESDAY = 'wednesday',
        THURSDAY = 'thursday',
        FRIDAY = 'friday',
        SATURDAY = 'saturday',
        SUNDAY = 'sunday',
    }
}

