/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TherapistLink } from './TherapistLink';
export type UpdateTherapistPayload = {
    name: string;
    slug: string;
    description: string | null;
    image: string | null;
    directoryEntryEnabled: boolean;
    acceptingNewClients: UpdateTherapistPayload.acceptingNewClients;
    publicClientRegistrationEnabled: boolean;
    link: TherapistLink | null;
};
export namespace UpdateTherapistPayload {
    export enum acceptingNewClients {
        NO = 'no',
        YES = 'yes',
        WAIT1TO2WEEKS = 'wait1To2Weeks',
        WAIT2TO4WEEKS = 'wait2To4Weeks',
        WAIT2MONTHS = 'wait2Months',
        WAIT3MONTHS = 'wait3Months',
        WAIT6MONTHS = 'wait6Months',
        WAIT1YEAR = 'wait1year',
    }
}

