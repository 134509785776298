/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateFeatureCommentPayload } from '../models/CreateFeatureCommentPayload';
import type { CreateFeatureRequestPayload } from '../models/CreateFeatureRequestPayload';
import type { Feature } from '../models/Feature';
import type { FeatureComment } from '../models/FeatureComment';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FeaturesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create feature
     * Create feature
     * @param requestBody
     * @returns Feature
     * @throws ApiError
     */
    public createFeature(
        requestBody: CreateFeatureRequestPayload,
    ): CancelablePromise<Feature> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/features',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update feature
     * Update feature
     * @param featureId
     * @param requestBody
     * @returns Feature
     * @throws ApiError
     */
    public updateFeature(
        featureId: number,
        requestBody: CreateFeatureRequestPayload,
    ): CancelablePromise<Feature> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/features/{featureId}',
            path: {
                'featureId': featureId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete feature
     * Delete feature
     * @param featureId
     * @returns void
     * @throws ApiError
     */
    public deleteFeature(
        featureId: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/features/{featureId}',
            path: {
                'featureId': featureId,
            },
        });
    }
    /**
     * Get feature with the context of the authenticated therapist
     * Get feature with context
     * @param featureId
     * @returns Feature
     * @throws ApiError
     */
    public getFeatureWithContext(
        featureId: number,
    ): CancelablePromise<Feature> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/features/{featureId}',
            path: {
                'featureId': featureId,
            },
        });
    }
    /**
     * Create feature comment
     * Create feature comment
     * @param featureId
     * @param requestBody
     * @returns FeatureComment
     * @throws ApiError
     */
    public createFeatureComment(
        featureId: number,
        requestBody: CreateFeatureCommentPayload,
    ): CancelablePromise<FeatureComment> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/features/{featureId}/comments',
            path: {
                'featureId': featureId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update feature comment
     * Update feature comment
     * @param featureId
     * @param commentId
     * @param requestBody
     * @returns FeatureComment
     * @throws ApiError
     */
    public updateFeatureComment(
        featureId: number,
        commentId: number,
        requestBody: CreateFeatureCommentPayload,
    ): CancelablePromise<FeatureComment> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/features/{featureId}/comments/{commentId}',
            path: {
                'featureId': featureId,
                'commentId': commentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete feature comment
     * Delete feature comment
     * @param featureId
     * @param commentId
     * @returns void
     * @throws ApiError
     */
    public deleteFeatureComment(
        featureId: number,
        commentId: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/features/{featureId}/comments/{commentId}',
            path: {
                'featureId': featureId,
                'commentId': commentId,
            },
        });
    }
    /**
     * Vote for feature
     * Vote for feature
     * @param featureId
     * @returns any
     * @throws ApiError
     */
    public voteFeature(
        featureId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/features/{featureId}/vote',
            path: {
                'featureId': featureId,
            },
        });
    }
    /**
     * Revoke vote for feature
     * Revoke vote for feature
     * @param featureId
     * @returns void
     * @throws ApiError
     */
    public revokeVoteFeature(
        featureId: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/features/{featureId}/vote',
            path: {
                'featureId': featureId,
            },
        });
    }
}
