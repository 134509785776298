/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TherapistEntity } from './TherapistEntity';
export type TherapistLinkEntity = {
    type: TherapistLinkEntity.type;
    target: string;
    therapist: TherapistEntity;
    therapistId: number;
};
export namespace TherapistLinkEntity {
    export enum type {
        APPOINTMENT = 'appointment',
        WEBSITE = 'website',
        EMAIL = 'email',
        PHONE = 'phone',
    }
}

