/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MinimalEventCategory } from './MinimalEventCategory';
export type MinimalEventWithCategory = {
    start: string;
    end: string;
    category: MinimalEventCategory;
    id: number;
    status: MinimalEventWithCategory.status;
    customTitle: string | null;
};
export namespace MinimalEventWithCategory {
    export enum status {
        OPEN = 'open',
        BOOKED = 'booked',
        APPROVED = 'approved',
        CANCELLED = 'cancelled',
        REJECTED = 'rejected',
        OUTAGE = 'outage',
        RESERVED = 'reserved',
    }
}

