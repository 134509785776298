export function sortAlphabetically(accounts: any[], key: string) {
    return accounts.sort((a, b) => {
        if (a[key] < b[key]) {
            return -1
        }
        if (a[key] > b[key]) {
            return 1
        }
        return 0
    })
}
