/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImportPlacePayload } from '../models/ImportPlacePayload';
import type { Place } from '../models/Place';
import type { SearchPlacePayload } from '../models/SearchPlacePayload';
import type { SearchPlaceResult } from '../models/SearchPlaceResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PlacesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Search for a places
     * Search Places
     * @param therapistId
     * @param requestBody
     * @returns SearchPlaceResult
     * @throws ApiError
     */
    public searchPlaces(
        therapistId: number,
        requestBody: SearchPlacePayload,
    ): CancelablePromise<Array<SearchPlaceResult>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/places/search',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Load a place
     * Load Place
     * @param therapistId
     * @param requestBody
     * @returns Place
     * @throws ApiError
     */
    public loadPlace(
        therapistId: number,
        requestBody: ImportPlacePayload,
    ): CancelablePromise<Place> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/places/load',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Import a place
     * Import Place
     * @param therapistId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public importPlace(
        therapistId: number,
        requestBody: ImportPlacePayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/places/import',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
