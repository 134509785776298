/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DateTime } from './DateTime';
import type { EnabledUserNotificationSettingEntity } from './EnabledUserNotificationSettingEntity';
import type { FeatureCommentEntity } from './FeatureCommentEntity';
import type { FeatureRequestEntity } from './FeatureRequestEntity';
import type { FeatureVoteEntity } from './FeatureVoteEntity';
import type { TherapistUserLinkingEntity } from './TherapistUserLinkingEntity';
export type UserEntity = {
    id: number;
    idpId: string;
    firstName: string;
    lastName: string;
    title: string | null;
    gender: UserEntity.gender;
    email: string;
    image: string | null;
    fromSocialLogin: boolean;
    isActive: boolean;
    deleted: boolean;
    createdAt: DateTime;
    therapists: Array<TherapistUserLinkingEntity>;
    enabledNotificationSettings: Array<EnabledUserNotificationSettingEntity>;
    featureRequests: Array<FeatureRequestEntity>;
    featureComments: Array<FeatureCommentEntity>;
    featureVotes: Array<FeatureVoteEntity>;
};
export namespace UserEntity {
    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
        DIVERSE = 'diverse',
        NOT_SET = 'not_set',
        DELETED = 'deleted',
    }
}

