/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TherapistEntity } from './TherapistEntity';
export type CustomNotificationTemplateEntity = {
    notification: CustomNotificationTemplateEntity.notification;
    content: string;
    therapist: TherapistEntity;
    therapistId: number;
};
export namespace CustomNotificationTemplateEntity {
    export enum notification {
        CLIENT_WELCOME = 'client-welcome',
        CLIENT_INVITATION = 'client-invitation',
        CLIENT_BOOKING_SUCCESS = 'client-booking-success',
        CLIENT_BOOKING_CONFIRMATION = 'client-booking-confirmation',
        CLIENT_BOOKING_REJECTION = 'client-booking-rejection',
        CLIENT_BOOKING_CANCELLATION = 'client-booking-cancellation',
        CLIENT_OUTAGE_FEE_APPLIES = 'client-outage-fee-applies',
        CLIENT_APPOINTMENT_REMINDERS = 'client-appointment-reminders',
    }
}

