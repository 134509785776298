'use client'

import { useCurrentClient, useCurrentUser } from '@tebuto/functions'
import { RootState } from '@tebuto/state/store'
import { setTherapistId } from '@tebuto/state/therapist'
import { usePathname } from 'next/navigation'
import { createContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ClientTherapistSelection from './selections/ClientTherapistSelection'

export const SelectedTherapistIdContext = createContext<number | null>(null)

export function TherapistSelectionGuard({ children }: { children: React.ReactNode }) {
    const dispatch = useDispatch()
    const selectedTherapistId = useSelector((state: RootState) => state.therapistContext.id)
    const currentUser = useCurrentUser()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (selectedTherapistId && currentUser.therapists.some(therapist => therapist.therapist.id === selectedTherapistId)) {
            setLoading(false)
            return
        }
        if (currentUser.therapists.length === 1) {
            dispatch(setTherapistId(currentUser.therapists[0].therapist.id))
            setLoading(false)
        }
    }, [currentUser])

    if (loading || !selectedTherapistId) {
        return <></>
    }
    return <SelectedTherapistIdContext.Provider value={selectedTherapistId}>{children}</SelectedTherapistIdContext.Provider>
}

export function TherapistClientSelectionGuard({ children }: { children: React.ReactNode }) {
    const dispatch = useDispatch()
    const pathname = usePathname()
    const selectedTherapistId = useSelector((state: RootState) => state.therapistContext.id)
    const currentClient = useCurrentClient()

    const excludedPaths = ['/einstellungen']

    if ((selectedTherapistId && currentClient.therapists.some(therapist => therapist.id === selectedTherapistId)) || excludedPaths.includes(pathname)) {
        return <SelectedTherapistIdContext.Provider value={selectedTherapistId}>{children}</SelectedTherapistIdContext.Provider>
    }

    if (currentClient.therapists.length === 1) {
        dispatch(setTherapistId(currentClient.therapists[0].id))
    }

    return <ClientTherapistSelection />
}
