/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { City } from '../models/City';
import type { CityWithTherapists } from '../models/CityWithTherapists';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CitiesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Search cities by name or zip
     * Search cities
     * @param name
     * @param zip
     * @returns any
     * @throws ApiError
     */
    public searchCity(
        name?: string,
        zip?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/cities/search',
            query: {
                'name': name,
                'zip': zip,
            },
        });
    }
    /**
     * Get city by geolocation
     * Get city for geolocation
     * @param latitude
     * @param longitude
     * @returns City
     * @throws ApiError
     */
    public getGeoCity(
        latitude: number,
        longitude: number,
    ): CancelablePromise<City> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/cities/geo',
            query: {
                'latitude': latitude,
                'longitude': longitude,
            },
        });
    }
    /**
     * Get City by Slug
     * Get City by Slug
     * @param slug
     * @returns CityWithTherapists
     * @throws ApiError
     */
    public getCity(
        slug: string,
    ): CancelablePromise<CityWithTherapists> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/cities/{slug}',
            path: {
                'slug': slug,
            },
        });
    }
}
