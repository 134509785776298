/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientRegistrationActionPayload } from '../models/ClientRegistrationActionPayload';
import type { LinkData } from '../models/LinkData';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LinkService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Link Data
     * Get Link Data
     * @param id
     * @returns LinkData
     * @throws ApiError
     */
    public getLinkData(
        id: string,
    ): CancelablePromise<LinkData> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/link/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Handle Link Action
     * Handle Link Action
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public handleLinkAction(
        id: string,
        requestBody: ClientRegistrationActionPayload,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/link/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
