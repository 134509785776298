/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationTemplate } from '../models/NotificationTemplate';
import type { ToggleUserNotification } from '../models/ToggleUserNotification';
import type { UpdateCustomNotificationTemplate } from '../models/UpdateCustomNotificationTemplate';
import type { UserNotificationSetting } from '../models/UserNotificationSetting';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class NotificationsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all notification settings
     * Get all notification settings
     * @param userId
     * @returns UserNotificationSetting
     * @throws ApiError
     */
    public getSettings(
        userId: number,
    ): CancelablePromise<Array<UserNotificationSetting>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{userId}/notifications/settings',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * Toggle notification setting
     * Toggle notification setting
     * @param userId
     * @param notification
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public toggleSetting(
        userId: number,
        notification: string,
        requestBody: ToggleUserNotification,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/{userId}/notifications/settings/{notification}',
            path: {
                'userId': userId,
                'notification': notification,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all custom notification templates
     * Get all custom notification templates
     * @param therapistId
     * @returns NotificationTemplate
     * @throws ApiError
     */
    public getTemplates(
        therapistId: number,
    ): CancelablePromise<Array<NotificationTemplate>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/notifications/templates',
            path: {
                'therapistId': therapistId,
            },
        });
    }
    /**
     * Get notification preview
     * Get notification preview
     * @param therapistId
     * @param notification
     * @returns string
     * @throws ApiError
     */
    public getPreview(
        therapistId: number,
        notification: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/notifications/{notification}/preview',
            path: {
                'therapistId': therapistId,
                'notification': notification,
            },
        });
    }
    /**
     * Update custom notification template
     * Get custom notification template
     * @param therapistId
     * @param notification
     * @param requestBody
     * @returns NotificationTemplate
     * @throws ApiError
     */
    public updateTemplate(
        therapistId: number,
        notification: string,
        requestBody: UpdateCustomNotificationTemplate,
    ): CancelablePromise<NotificationTemplate> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/therapists/{therapistId}/notifications/{notification}/template',
            path: {
                'therapistId': therapistId,
                'notification': notification,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Reset custom notification template
     * Reset custom notification template
     * @param therapistId
     * @param notification
     * @returns void
     * @throws ApiError
     */
    public resetTemplate(
        therapistId: number,
        notification: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/notifications/{notification}/template/reset',
            path: {
                'therapistId': therapistId,
                'notification': notification,
            },
        });
    }
}
