/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookingEntity } from './BookingEntity';
export type OnlineMeetingEntity = {
    id: number;
    externalId: string;
    joinUrl: string;
    provider: OnlineMeetingEntity.provider;
    booking: BookingEntity;
};
export namespace OnlineMeetingEntity {
    export enum provider {
        ZOOM = 'zoom',
        MICROSOFT_TEAMS = 'microsoft-teams',
    }
}

