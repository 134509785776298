'use client'

import useRouter from '@tebuto/guards/leave-page/useRouter'
import ConfirmationDialogue from '@tebuto/modals/ConfirmationDialogue'
import COMPONENT_TEXTS from '@tebuto/translations'
import { useState } from 'react'
import { Menu, MenuEntry } from '../Menu'

export default function Navbar() {
    const router = useRouter()
    const [logoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false)

    const menuItems: MenuEntry[] = [
        {
            name: COMPONENT_TEXTS.layout.client.settings,
            href: '/einstellungen'
        },
        {
            name: COMPONENT_TEXTS.layout.client.logout,
            onClick: () => setLogoutConfirmationOpen(true)
        }
    ]

    function onLogout() {
        router.push(`${process.env.NEXT_PUBLIC_CLIENTS_API_URL}/auth/logout`)
    }

    return (
        <>
            <Menu items={menuItems} baseUrl={`${process.env.NEXT_PUBLIC_CLIENTS_BASE_URL}`} />
            <ConfirmationDialogue
                open={logoutConfirmationOpen}
                setOpen={setLogoutConfirmationOpen}
                onSubmit={onLogout}
                title={COMPONENT_TEXTS.layout.client.confirmLogout}
                submitButtonText={COMPONENT_TEXTS.layout.client.logout}
            />
        </>
    )
}
