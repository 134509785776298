/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Therapist } from '../models/Therapist';
import type { UpdateTherapistPayload } from '../models/UpdateTherapistPayload';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TherapistsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get therapist
     * Get therapist
     * @param therapistId
     * @returns Therapist
     * @throws ApiError
     */
    public getTherapist(
        therapistId: number,
    ): CancelablePromise<Therapist> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}',
            path: {
                'therapistId': therapistId,
            },
        });
    }
    /**
     * Update therapist
     * Update therapist
     * @param therapistId
     * @param requestBody
     * @returns Therapist
     * @throws ApiError
     */
    public updateTherapist(
        therapistId: number,
        requestBody: UpdateTherapistPayload,
    ): CancelablePromise<Therapist> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/therapists/{therapistId}',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete therapist
     * Delete therapist
     * @param therapistId
     * @returns void
     * @throws ApiError
     */
    public requestTherapistDeletion(
        therapistId: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/therapists/{therapistId}',
            path: {
                'therapistId': therapistId,
            },
        });
    }
}
