/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckoutSessionData } from '../models/CheckoutSessionData';
import type { CreateCheckoutSessionPayload } from '../models/CreateCheckoutSessionPayload';
import type { CreatePortalSessionPayload } from '../models/CreatePortalSessionPayload';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PaymentService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Checkout Session for Stripe Payment Gateway
     * Create Checkout Session
     * @param therapistId
     * @param requestBody
     * @returns CheckoutSessionData
     * @throws ApiError
     */
    public createCheckoutSession(
        therapistId: number,
        requestBody: CreateCheckoutSessionPayload,
    ): CancelablePromise<CheckoutSessionData> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/payment/create-checkout-session',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Complete Checkout Session for Stripe Payment Gateway
     * Complete Checkout Session
     * @param therapistId
     * @param sessionId
     * @returns any
     * @throws ApiError
     */
    public completeCheckoutSession(
        therapistId: number,
        sessionId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/payment/checkout-session-completed',
            path: {
                'therapistId': therapistId,
            },
            query: {
                'session_id': sessionId,
            },
            errors: {
                302: `Redirect to Tebuto Therapists Webapp`,
            },
        });
    }
    /**
     * Create Portal Session for Stripe Payment Gateway
     * Create Portal Session
     * @param therapistId
     * @param requestBody
     * @returns string
     * @throws ApiError
     */
    public createPortalSession(
        therapistId: number,
        requestBody: CreatePortalSessionPayload,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/payment/create-portal-session',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Invoices for a specific Therapist
     * Get Invoices
     * @param therapistId
     * @returns any
     * @throws ApiError
     */
    public getInvoices(
        therapistId: number,
    ): CancelablePromise<Array<Record<string, any>>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/payment/invoices',
            path: {
                'therapistId': therapistId,
            },
        });
    }
}
