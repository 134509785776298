/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OnlineMeeting } from './OnlineMeeting';
export type BookedClientEvent = {
    start: string;
    end: string;
    bookedAt: string;
    id: number;
    title: string;
    isConfirmed: boolean;
    isOutage: boolean;
    location: BookedClientEvent.location;
    categoryId: number;
    onlineMeeting: OnlineMeeting;
};
export namespace BookedClientEvent {
    export enum location {
        VIRTUAL = 'virtual',
        ONSITE = 'onsite',
    }
}

