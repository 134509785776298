/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressEntity } from './AddressEntity';
export type CityEntity = {
    zip: string;
    name: string;
    slug: string;
    latitude: number;
    longitude: number;
    geometry: string;
    federalStateCode: CityEntity.federalStateCode;
    countryCode: CityEntity.countryCode;
    addresses: Array<AddressEntity>;
};
export namespace CityEntity {
    export enum federalStateCode {
        BW = 'BW',
        BY = 'BY',
        BE = 'BE',
        BB = 'BB',
        HB = 'HB',
        HH = 'HH',
        HE = 'HE',
        MV = 'MV',
        NI = 'NI',
        NW = 'NW',
        RP = 'RP',
        SL = 'SL',
        SN = 'SN',
        ST = 'ST',
        SH = 'SH',
        TH = 'TH',
    }
    export enum countryCode {
        DE = 'DE',
    }
}

