/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientNotificationSetting } from '../models/ClientNotificationSetting';
import type { LeaveTherapistRequestPayload } from '../models/LeaveTherapistRequestPayload';
import type { RequestEmailChangePayload } from '../models/RequestEmailChangePayload';
import type { ToggleClientNotification } from '../models/ToggleClientNotification';
import type { UpdateOwnClientPayload } from '../models/UpdateOwnClientPayload';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ClientsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Update Client
     * Update the client
     * @param clientId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public updateClient(
        clientId: number,
        requestBody: UpdateOwnClientPayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients/{clientId}',
            path: {
                'clientId': clientId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Request Email Change
     * Request an email with a link to change the email address
     * @param clientId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public requestEmailChange(
        clientId: number,
        requestBody: RequestEmailChangePayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients/{clientId}/request-email-change',
            path: {
                'clientId': clientId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Request Leave Therapist
     * Request an email with a link to leave the therapist
     * @param clientId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public requestLeaveTherapist(
        clientId: number,
        requestBody: LeaveTherapistRequestPayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients/{clientId}/request-leave-therapist',
            path: {
                'clientId': clientId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Request Deletion
     * Request an email with a link to delete the client
     * @param clientId
     * @returns any
     * @throws ApiError
     */
    public requestDeletion(
        clientId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clients/{clientId}/request-deletion',
            path: {
                'clientId': clientId,
            },
        });
    }
    /**
     * Get all notification settings
     * Get all notification settings
     * @param clientId
     * @returns ClientNotificationSetting
     * @throws ApiError
     */
    public getSettings(
        clientId: number,
    ): CancelablePromise<Array<ClientNotificationSetting>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients/{clientId}/notifications/settings',
            path: {
                'clientId': clientId,
            },
        });
    }
    /**
     * Toggle notification setting
     * Toggle notification setting
     * @param clientId
     * @param notification
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public toggleSetting(
        clientId: number,
        notification: string,
        requestBody: ToggleClientNotification,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/clients/{clientId}/notifications/settings/{notification}',
            path: {
                'clientId': clientId,
                'notification': notification,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
