/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TherapistEntity } from './TherapistEntity';
export type ContractEntity = {
    plan: ContractEntity.plan;
    stripeSubscriptionId: string | null;
    endDate: Record<string, any> | null;
    renew: boolean;
    trialPeriodUsed: boolean;
    therapist: TherapistEntity;
    therapistId: number;
};
export namespace ContractEntity {
    export enum plan {
        FREE = 'free',
        PAID = 'paid',
    }
}

