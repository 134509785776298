/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserEntity } from './UserEntity';
export type EnabledUserNotificationSettingEntity = {
    notification: EnabledUserNotificationSettingEntity.notification;
    channel: EnabledUserNotificationSettingEntity.channel;
    user: UserEntity;
    userId: number;
};
export namespace EnabledUserNotificationSettingEntity {
    export enum notification {
        USER_BOOKING_NOTICE = 'user-booking-notice',
        USER_BOOKING_CANCELLATION = 'user-booking-cancellation',
        USER_CLIENT_LEFT_THERAPIST = 'user-client-left-therapist',
    }
    export enum channel {
        EMAIL = 'email',
    }
}

