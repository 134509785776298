/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UserNotificationSetting = {
    notification: UserNotificationSetting.notification;
    enabled: boolean;
};
export namespace UserNotificationSetting {
    export enum notification {
        USER_BOOKING_NOTICE = 'user-booking-notice',
        USER_BOOKING_CANCELLATION = 'user-booking-cancellation',
        USER_CLIENT_LEFT_THERAPIST = 'user-client-left-therapist',
    }
}

