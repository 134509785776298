/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Characteristic = {
    id: number;
    key: string;
    label: string;
    category: Characteristic.category;
    verified: boolean;
};
export namespace Characteristic {
    export enum category {
        ADMISSIONS = 'admissions',
        PROCEDURES = 'procedures',
        BILLING = 'billing',
        SERVICES = 'services',
        CORNERSTONES = 'cornerstones',
        EXCLUDED_CORNERSTONES = 'excludedCornerstones',
    }
}

