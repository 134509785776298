/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Characteristic } from '../models/Characteristic';
import type { CreateCharacteristicPayload } from '../models/CreateCharacteristicPayload';
import type { SelectedCharacteristic } from '../models/SelectedCharacteristic';
import type { UpdateCharacteristicPayload } from '../models/UpdateCharacteristicPayload';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CharacteristicsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get the characteristics of a therapist
     * Get Characteristics
     * @param therapistId
     * @returns SelectedCharacteristic
     * @throws ApiError
     */
    public getCharacteristics(
        therapistId: number,
    ): CancelablePromise<Array<SelectedCharacteristic>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/characteristics',
            path: {
                'therapistId': therapistId,
            },
        });
    }
    /**
     * Create and add a characteristic for a therapist
     * Upsert Characteristic
     * @param therapistId
     * @param requestBody
     * @returns Characteristic
     * @throws ApiError
     */
    public upsertCharacteristic(
        therapistId: number,
        requestBody: CreateCharacteristicPayload,
    ): CancelablePromise<Characteristic> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/characteristics',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update the characteristics of a therapist
     * Update Characteristics
     * @param therapistId
     * @param requestBody
     * @returns SelectedCharacteristic
     * @throws ApiError
     */
    public updateCharacteristics(
        therapistId: number,
        requestBody: UpdateCharacteristicPayload,
    ): CancelablePromise<Array<SelectedCharacteristic>> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/therapists/{therapistId}/characteristics',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
