/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MinimalPublicTherapist } from './MinimalPublicTherapist';
export type CityWithTherapists = {
    zip: string;
    name: string;
    slug: string;
    federalStateCode: CityWithTherapists.federalStateCode;
    countryCode: CityWithTherapists.countryCode;
    therapists: Array<MinimalPublicTherapist>;
};
export namespace CityWithTherapists {
    export enum federalStateCode {
        BW = 'BW',
        BY = 'BY',
        BE = 'BE',
        BB = 'BB',
        HB = 'HB',
        HH = 'HH',
        HE = 'HE',
        MV = 'MV',
        NI = 'NI',
        NW = 'NW',
        RP = 'RP',
        SL = 'SL',
        SN = 'SN',
        ST = 'ST',
        SH = 'SH',
        TH = 'TH',
    }
    export enum countryCode {
        DE = 'DE',
    }
}

