/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WhoAmITherapistUserLinking } from './WhoAmITherapistUserLinking';
export type WhoAmI = {
    id: number;
    firstName: string;
    lastName: string;
    title: string | null;
    gender: WhoAmI.gender;
    email: string;
    image: string | null;
    fromSocialLogin: boolean;
    isActive: boolean;
    therapists: Array<WhoAmITherapistUserLinking>;
};
export namespace WhoAmI {
    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
        DIVERSE = 'diverse',
        NOT_SET = 'not_set',
        DELETED = 'deleted',
    }
}

