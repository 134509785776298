/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MinimalUser } from './MinimalUser';
export type TherapistUserLinkingWithUserInfo = {
    role: TherapistUserLinkingWithUserInfo.role;
    user: MinimalUser;
};
export namespace TherapistUserLinkingWithUserInfo {
    export enum role {
        THERAPIST = 'therapist',
        USER = 'user',
    }
}

