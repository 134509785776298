/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type LanguageOption = {
    id?: number;
    language: LanguageOption.language;
    level: LanguageOption.level;
    selected: boolean;
};
export namespace LanguageOption {
    export enum language {
        DE = 'de',
        TR = 'tr',
        PL = 'pl',
        RU = 'ru',
        AR = 'ar',
        KU = 'ku',
        EN = 'en',
        IT = 'it',
        EL = 'el',
        RO = 'ro',
        SR = 'sr',
        HR = 'hr',
        ES = 'es',
        PT = 'pt',
        BG = 'bg',
        NL = 'nl',
        FR = 'fr',
        VI = 'vi',
        FA = 'fa',
        ZH = 'zh',
        UK = 'uk',
        SQ = 'sq',
        HU = 'hu',
        PS = 'ps',
        BS = 'bs',
        HI = 'hi',
        UR = 'ur',
        DA = 'da',
        SV = 'sv',
        BN = 'bn',
        TA = 'ta',
        FI = 'fi',
        SK = 'sk',
        CS = 'cs',
        MK = 'mk',
        TH = 'th',
        LT = 'lt',
        SO = 'so',
        HE = 'he',
        ML = 'ml',
        AZ = 'az',
        AM = 'am',
        HY = 'hy',
        TI = 'ti',
        KA = 'ka',
        KK = 'kk',
        SI = 'si',
        SL = 'sl',
        LV = 'lv',
        ET = 'et',
    }
    export enum level {
        NATIVE = 'native',
        FLUENT = 'fluent',
        BASIC = 'basic',
        NO_ENTRY = 'noEntry',
    }
}

