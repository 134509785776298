'use client'

import { SWRConfig } from 'swr'

export default function SWRConfiguration({ children }: { children: React.ReactNode }) {
    return (
        <SWRConfig
            value={{
                refreshInterval: 0,
                revalidateIfStale: false,
                revalidateOnFocus: false,
                revalidateOnReconnect: false,
                revalidateOnMount: true,
                focusThrottleInterval: 60000,
                errorRetryInterval: 1000,
                errorRetryCount: 3,
                loadingTimeout: 500 // we only show loading animations after 500ms
            }}
        >
            {children}
        </SWRConfig>
    )
}
