import { createHash } from 'crypto'
import { WhoAmI } from '@tebuto/api/therapists/models/WhoAmI'

export function resolveUserProfileImage(user: WhoAmI, size: 'small' | 'large' = 'small'): string {
    if (user.image) {
        return user.image
    }

    const emailHash = createHash('sha256').update(user.email.trim().toLowerCase()).digest('hex')
    return `https://gravatar.com/avatar/${emailHash}?d=404&${size === 'small' ? 's=100' : 's=240'}`
}
