'use client'

import { ApiError } from '@tebuto/api/clients'
import { WhoAmIClient } from '@tebuto/api/clients/models/WhoAmIClient'
import { useClientsApiRequest } from '@tebuto/functions'
import useRouter from '@tebuto/guards/leave-page/useRouter'
import { LoadingScreen } from '@tebuto/layout/LoadingScreen'
import PageError from '@tebuto/layout/PageError'
import { createContext, useEffect } from 'react'

export const CurrentClientContext = createContext<WhoAmIClient | null>(null)

export function CurrentClientGuard({ children }: { children: React.ReactNode }): JSX.Element {
    const router = useRouter()

    const { data: clientContext, isLoading, error } = useClientsApiRequest('client-who-am-i', api => api.whoAmI.clientWhoAmI())

    useEffect(() => {
        if (error instanceof ApiError && (error.status === 401 || error.status === 403)) {
            router.push('/login')
        }
    }, [error])

    if (isLoading) {
        return <LoadingScreen show={isLoading} />
    }

    if (error || !clientContext) {
        return <PageError reset={() => router.refresh()} />
    }

    return <CurrentClientContext.Provider value={clientContext}>{children}</CurrentClientContext.Provider>
}
