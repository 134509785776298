/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DateTime } from './DateTime';
import type { TherapistEntity } from './TherapistEntity';
export type OpeningEntity = {
    id: number;
    day: OpeningEntity.day;
    startTime: DateTime;
    endTime: DateTime;
    therapist: TherapistEntity;
};
export namespace OpeningEntity {
    export enum day {
        MONDAY = 'monday',
        TUESDAY = 'tuesday',
        WEDNESDAY = 'wednesday',
        THURSDAY = 'thursday',
        FRIDAY = 'friday',
        SATURDAY = 'saturday',
        SUNDAY = 'sunday',
    }
}

