/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SetOutageFeeAppliesPayload = {
    outageFeeApplies: SetOutageFeeAppliesPayload.outageFeeApplies;
};
export namespace SetOutageFeeAppliesPayload {
    export enum outageFeeApplies {
        YES = 'yes',
        NO = 'no',
        NOT_SELECTED = 'not-selected',
    }
}

