/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ThirdPartyAccount = {
    id: number;
    name: string;
    type: ThirdPartyAccount.type;
    provider: string;
};
export namespace ThirdPartyAccount {
    export enum type {
        CALENDAR = 'calendar',
        MEETING = 'meeting',
    }
}

