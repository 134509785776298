/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WhoAmIClient } from '../models/WhoAmIClient';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class WhoAmIService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get who I am for Client
     * Endpoint to evaluate information on the current client
     * @returns WhoAmIClient
     * @throws ApiError
     */
    public clientWhoAmI(): CancelablePromise<WhoAmIClient> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/who-am-i',
        });
    }
}
