/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContactInfo } from '../models/ContactInfo';
import type { UpdateContactInformationPayload } from '../models/UpdateContactInformationPayload';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ContactInformationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all contact information
     * Get all contact information
     * @param therapistId
     * @returns ContactInfo
     * @throws ApiError
     */
    public getAllContactInformation(
        therapistId: number,
    ): CancelablePromise<Array<ContactInfo>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/contact-information',
            path: {
                'therapistId': therapistId,
            },
        });
    }
    /**
     * Update contact information
     * Update contact information
     * @param therapistId
     * @param requestBody
     * @returns ContactInfo
     * @throws ApiError
     */
    public updateContactInformation(
        therapistId: number,
        requestBody: UpdateContactInformationPayload,
    ): CancelablePromise<Array<ContactInfo>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/therapists/{therapistId}/contact-information',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
