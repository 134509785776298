/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContactFormPayload } from '../models/ContactFormPayload';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ContactService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Send contact request
     * Send contact request
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public sendContactRequest(
        requestBody: ContactFormPayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/contact',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
