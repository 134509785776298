/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AuthService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Authorize
     * Authorization endpoint
     * @param returnTo
     * @returns any
     * @throws ApiError
     */
    public authorization(
        returnTo: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/authorize',
            query: {
                'returnTo': returnTo,
            },
        });
    }
    /**
     * Callback
     * Callback endpoint
     * @returns any
     * @throws ApiError
     */
    public callback(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/callback',
        });
    }
    /**
     * Logout Redirect
     * Logout Redirect endpoint
     * @returns any
     * @throws ApiError
     */
    public logoutRedirect(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/logout-redirect',
        });
    }
    /**
     * Logout
     * Logout endpoint
     * @returns any
     * @throws ApiError
     */
    public logout(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/logout',
        });
    }
}
