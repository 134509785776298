/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalendarEntity } from './CalendarEntity';
import type { TherapistUserLinkingEntity } from './TherapistUserLinkingEntity';
export type ThirdPartyAccountEntity = {
    id: number;
    name: string;
    type: ThirdPartyAccountEntity.type;
    externalId: string;
    data: Record<string, any>;
    therapistUserLinking: TherapistUserLinkingEntity;
    calendars: Array<CalendarEntity>;
};
export namespace ThirdPartyAccountEntity {
    export enum type {
        CALENDAR = 'calendar',
        MEETING = 'meeting',
    }
}

