/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookedClientEvent } from '../models/BookedClientEvent';
import type { CancelBookingByClientPayload } from '../models/CancelBookingByClientPayload';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class BookingsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all bookings of a client
     * Get all bookings of a client
     * @param therapistId
     * @returns BookedClientEvent
     * @throws ApiError
     */
    public getClientBookings(
        therapistId: number,
    ): CancelablePromise<Array<BookedClientEvent>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/bookings',
            path: {
                'therapistId': therapistId,
            },
        });
    }
    /**
     * Cancel a booking of a client
     * Cancel a booking of a client
     * @param therapistId
     * @param bookingId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public cancelBooking(
        therapistId: number,
        bookingId: number,
        requestBody: CancelBookingByClientPayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/bookings/{bookingId}/cancel',
            path: {
                'therapistId': therapistId,
                'bookingId': bookingId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
