/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublicTherapist } from '../models/PublicTherapist';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TherapistsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Therapist by ID
     * Get Therapist by ID
     * @param id
     * @returns PublicTherapist
     * @throws ApiError
     */
    public getTherapist(
        id: number,
    ): CancelablePromise<PublicTherapist> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{id}',
            path: {
                'id': id,
            },
        });
    }
}
