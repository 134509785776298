/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MinimalClient } from './MinimalClient';
import type { OnlineMeeting } from './OnlineMeeting';
export type BookingWithoutEvent = {
    createdAt: string;
    id: number;
    locationSelection: BookingWithoutEvent.locationSelection;
    isConfirmed: boolean;
    isOutage: boolean;
    outageFeeApplies: BookingWithoutEvent.outageFeeApplies | null;
    client: MinimalClient;
    onlineMeeting: OnlineMeeting | null;
};
export namespace BookingWithoutEvent {
    export enum locationSelection {
        VIRTUAL = 'virtual',
        ONSITE = 'onsite',
    }
    export enum outageFeeApplies {
        YES = 'yes',
        NO = 'no',
        NOT_SELECTED = 'not-selected',
    }
}

