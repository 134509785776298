/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WhoAmITherapist } from './WhoAmITherapist';
export type WhoAmITherapistUserLinking = {
    role: WhoAmITherapistUserLinking.role;
    therapist: WhoAmITherapist;
};
export namespace WhoAmITherapistUserLinking {
    export enum role {
        THERAPIST = 'therapist',
        USER = 'user',
    }
}

