/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TherapistLink = {
    type: TherapistLink.type;
    target: string;
};
export namespace TherapistLink {
    export enum type {
        APPOINTMENT = 'appointment',
        WEBSITE = 'website',
        EMAIL = 'email',
        PHONE = 'phone',
    }
}

