/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TherapistEntity } from './TherapistEntity';
export type ContactInfoEntity = {
    id: number;
    type: ContactInfoEntity.type;
    value: string;
    position: number;
    therapist: TherapistEntity;
};
export namespace ContactInfoEntity {
    export enum type {
        EMAIL = 'email',
        PHONE = 'phone',
        WEBSITE = 'website',
        WHATSAPP = 'whatsapp',
        LINKEDIN = 'linkedin',
    }
}

