/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MinimalWhoAmITherapist } from './MinimalWhoAmITherapist';
export type WhoAmIClient = {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    gender: WhoAmIClient.gender;
    isActive: boolean;
    therapists: Array<MinimalWhoAmITherapist>;
};
export namespace WhoAmIClient {
    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
        DIVERSE = 'diverse',
        NOT_SET = 'not_set',
        DELETED = 'deleted',
    }
}

