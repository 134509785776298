/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DateTime } from './DateTime';
import type { FeatureCommentEntity } from './FeatureCommentEntity';
import type { FeatureVoteEntity } from './FeatureVoteEntity';
import type { UserEntity } from './UserEntity';
export type FeatureRequestEntity = {
    id: number;
    userId: number;
    title: string;
    isAnonymous: boolean;
    createdAt: DateTime;
    slug: string;
    description: string;
    status: FeatureRequestEntity.status;
    user: UserEntity | null;
    votes: Array<FeatureVoteEntity>;
    comments: Array<FeatureCommentEntity>;
};
export namespace FeatureRequestEntity {
    export enum status {
        NEW = 'new',
        PLANNED = 'planned',
        IN_PROGRESS = 'in_progress',
        DONE = 'done',
    }
}

