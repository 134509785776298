/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TherapistEntity } from './TherapistEntity';
import type { ThirdPartyAccountEntity } from './ThirdPartyAccountEntity';
import type { UserEntity } from './UserEntity';
export type TherapistUserLinkingEntity = {
    id: number;
    role: TherapistUserLinkingEntity.role;
    userId: number;
    therapistId: number;
    user: UserEntity;
    therapist: TherapistEntity;
    thirdPartyAccounts: Array<ThirdPartyAccountEntity>;
};
export namespace TherapistUserLinkingEntity {
    export enum role {
        THERAPIST = 'therapist',
        USER = 'user',
    }
}

