/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ConnectThirdPartyAccountPayload = {
    calendarProvider?: ConnectThirdPartyAccountPayload.calendarProvider;
    meetingProvider?: ConnectThirdPartyAccountPayload.meetingProvider;
    type: ConnectThirdPartyAccountPayload.type;
    migrateMeetings?: boolean;
};
export namespace ConnectThirdPartyAccountPayload {
    export enum calendarProvider {
        GOOGLE = 'google',
        MICROSOFT = 'microsoft',
    }
    export enum meetingProvider {
        ZOOM = 'zoom',
        MICROSOFT_TEAMS = 'microsoft-teams',
    }
    export enum type {
        CALENDAR = 'calendar',
        MEETING = 'meeting',
    }
}

