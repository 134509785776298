/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConnectThirdPartyAccountPayload } from '../models/ConnectThirdPartyAccountPayload';
import type { ThirdPartyAccount } from '../models/ThirdPartyAccount';
import type { ThirdPartyAccountType } from '../models/ThirdPartyAccountType';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ThirdPartyAccountsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Third Party Accounts
     * Get Third Party Accounts
     * @param therapistId
     * @param type
     * @returns ThirdPartyAccount
     * @throws ApiError
     */
    public getThirdPartyAccounts(
        therapistId: number,
        type?: ThirdPartyAccountType,
    ): CancelablePromise<Array<ThirdPartyAccount>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/third-party-accounts',
            path: {
                'therapistId': therapistId,
            },
            query: {
                'type': type,
            },
        });
    }
    /**
     * Connect Third Party Account
     * Connect Third Party Account
     * @param therapistId
     * @param requestBody
     * @returns string
     * @throws ApiError
     */
    public connectThirdPartyAccount(
        therapistId: number,
        requestBody: ConnectThirdPartyAccountPayload,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/third-party-accounts',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Disconnect Third Party Calendar Account
     * Disconnect Calendar Account
     * @param therapistId
     * @param accountId
     * @param deleteMeetings
     * @returns void
     * @throws ApiError
     */
    public disconnectThirdPartyAccount(
        therapistId: number,
        accountId: number,
        deleteMeetings?: boolean,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/therapists/{therapistId}/third-party-accounts/{accountId}',
            path: {
                'therapistId': therapistId,
                'accountId': accountId,
            },
            query: {
                'deleteMeetings': deleteMeetings,
            },
        });
    }
}
