/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DateTime } from './DateTime';
export type BookEventForCustomerPayload = {
    eventRuleId: number;
    start: DateTime;
    end: DateTime;
    clientId: number;
    locationSelection?: BookEventForCustomerPayload.locationSelection;
};
export namespace BookEventForCustomerPayload {
    export enum locationSelection {
        VIRTUAL = 'virtual',
        ONSITE = 'onsite',
    }
}

