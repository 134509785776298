/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BookExistingEventPayload = {
    locationSelection?: BookExistingEventPayload.locationSelection;
};
export namespace BookExistingEventPayload {
    export enum locationSelection {
        VIRTUAL = 'virtual',
        ONSITE = 'onsite',
    }
}

