/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SESBounce } from './SESBounce';
import type { SESComplaint } from './SESComplaint';
import type { SESMail } from './SESMail';
export type AWSNotification = {
    Type: AWSNotification.Type;
    MessageId: string;
    TopicArn: string;
    Message: string;
    Timestamp: string;
    SignatureVersion: string;
    Signature: string;
    SigningCertURL: string;
    SubscribeURL?: string;
    Token?: string;
    Subject?: string;
    eventType?: AWSNotification.eventType;
    mail?: SESMail;
    bounce?: SESBounce;
    complaint?: SESComplaint;
};
export namespace AWSNotification {
    export enum Type {
        SUBSCRIPTION_CONFIRMATION = 'SubscriptionConfirmation',
        UNSUBSCRIBE_CONFIRMATION = 'UnsubscribeConfirmation',
        NOTIFICATION = 'Notification',
    }
    export enum eventType {
        BOUNCE = 'Bounce',
        COMPLAINT = 'Complaint',
    }
}

