/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type LinkData = {
    id: string;
    type: LinkData.type;
    payload: Record<string, any>;
};
export namespace LinkData {
    export enum type {
        CLIENT_INVITATION = 'client-invitation',
        CLIENT_EMAIL_VERIFICATION = 'client-email-verification',
        CLIENT_LEAVE_THERAPIST = 'client-leave-therapist',
        CLIENT_DELETION = 'client-deletion',
        CLIENT_DISABLE_NOTIFICATION = 'client-disable-notification',
        USER_EMAIL_VERIFICATION = 'user-email-verification',
        USER_DELETION = 'user-deletion',
        USER_DISABLE_NOTIFICATION = 'user-disable-notification',
    }
}

