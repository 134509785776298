import Link from 'next/link'
import { Button } from './Button'

interface LinkButtonProps {
    href: string
    title: string
    className?: string
    outlined?: boolean
    target?: string
    disabled?: boolean
}

export default function LinkButton({ href, title, className, outlined, target, disabled, ...otherProps }: LinkButtonProps & React.HTMLAttributes<HTMLAnchorElement>) {
    if (disabled) {
        return Button({ title, disabled: true, className, outline: outlined })
    }

    const boxStyles = 'bg-primary text-white hover:bg-primar'
    const outlinedStyles = 'border border-primary-500 text-primary-500 hover:bg-primary-500 hover:text-white duration-300 hover:shadow-lg'

    return (
        <Link
            href={href}
            className={`px-4 h-[35px] flex items-center rounded-md text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 ${
                outlined ? outlinedStyles : boxStyles
            } ${className}`}
            target={target}
            {...otherProps}
        >
            {title}
        </Link>
    )
}
