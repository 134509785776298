/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WhoAmI } from '../models/WhoAmI';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class WhoAmIService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get who I am
     * Endpoint to evaluate information on the current user
     * @returns WhoAmI
     * @throws ApiError
     */
    public whoAmI(): CancelablePromise<WhoAmI> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/who-am-i',
        });
    }
}
