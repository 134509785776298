/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreateCharacteristicPayload = {
    label: string;
    category: CreateCharacteristicPayload.category;
};
export namespace CreateCharacteristicPayload {
    export enum category {
        ADMISSIONS = 'admissions',
        PROCEDURES = 'procedures',
        BILLING = 'billing',
        SERVICES = 'services',
        CORNERSTONES = 'cornerstones',
        EXCLUDED_CORNERSTONES = 'excludedCornerstones',
    }
}

