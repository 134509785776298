/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FulfillPreconditionPayload } from '../models/FulfillPreconditionPayload';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PreconditionsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Fulfills a therapist precondition
     * Fulfill Therapist Precondition
     * @param therapistId
     * @param preconditionId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public fullfilTherapistPrecondition(
        therapistId: number,
        preconditionId: number,
        requestBody: FulfillPreconditionPayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/preconditions/{preconditionId}/fulfill',
            path: {
                'therapistId': therapistId,
                'preconditionId': preconditionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
