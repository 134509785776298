/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OnlineMeeting = {
    id: number;
    joinUrl: string;
    provider: OnlineMeeting.provider;
};
export namespace OnlineMeeting {
    export enum provider {
        ZOOM = 'zoom',
        MICROSOFT_TEAMS = 'microsoft-teams',
    }
}

