/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AWSNotification } from '../models/AWSNotification';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AwsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Webhook Endpoint for AWS Notifications
     * Webhook Endpoint for AWS Notifications
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public notifications(
        requestBody: AWSNotification,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/aws/notifications',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
