'use client'

import { usePathname } from 'next/navigation'

export function transformTebutoLink(path: string) {
    let transformedPath = path

    if (path.startsWith('/app')) {
        transformedPath = process.env.NEXT_PUBLIC_APP_BASE_URL + path.replace('/app', '')
    } else if (path.startsWith('/landing')) {
        transformedPath = process.env.NEXT_PUBLIC_LANDING_BASE_URL + path.replace('/landing', '')
    } else if (path.startsWith('/clients')) {
        transformedPath = process.env.NEXT_PUBLIC_CLIENTS_BASE_URL + path.replace('/clients', '')
    }

    return transformedPath
}

export function loginAndReturnLink(app: 'client' | 'app' | 'landing') {
    const pathName = usePathname()
    const currentPath = transformTebutoLink(`/${app}${pathName}`)
    const authorizeThenReturnUrl = new URL(process.env.NEXT_PUBLIC_THERAPISTS_AUTHORIZE_URL || '')
    authorizeThenReturnUrl.searchParams.set('returnTo', currentPath)
    return authorizeThenReturnUrl.toString()
}
