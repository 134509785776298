/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MinimalClient } from './MinimalClient';
import type { MinimalEventWithCategory } from './MinimalEventWithCategory';
import type { OnlineMeeting } from './OnlineMeeting';
export type Booking = {
    createdAt: string;
    id: number;
    locationSelection: Booking.locationSelection;
    isConfirmed: boolean;
    isOutage: boolean;
    outageFeeApplies: Booking.outageFeeApplies | null;
    event: MinimalEventWithCategory;
    client: MinimalClient;
    onlineMeeting: OnlineMeeting | null;
};
export namespace Booking {
    export enum locationSelection {
        VIRTUAL = 'virtual',
        ONSITE = 'onsite',
    }
    export enum outageFeeApplies {
        YES = 'yes',
        NO = 'no',
        NOT_SELECTED = 'not-selected',
    }
}

