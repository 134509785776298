/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TherapistEntity } from './TherapistEntity';
export type TherapistCharacteristicEntity = {
    id: number;
    key: string;
    label: string;
    category: TherapistCharacteristicEntity.category;
    verified: boolean;
    therapists: Array<TherapistEntity>;
};
export namespace TherapistCharacteristicEntity {
    export enum category {
        ADMISSIONS = 'admissions',
        PROCEDURES = 'procedures',
        BILLING = 'billing',
        SERVICES = 'services',
        CORNERSTONES = 'cornerstones',
        EXCLUDED_CORNERSTONES = 'excludedCornerstones',
    }
}

