/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ToggleUserNotification = {
    enabled: boolean;
    channel: ToggleUserNotification.channel;
};
export namespace ToggleUserNotification {
    export enum channel {
        EMAIL = 'email',
    }
}

