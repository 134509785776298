/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TherapistSettings } from '../models/TherapistSettings';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SettingsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get the settings of a therapist
     * Get Settings
     * @param therapistId
     * @returns TherapistSettings
     * @throws ApiError
     */
    public getSettings(
        therapistId: number,
    ): CancelablePromise<TherapistSettings> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/settings',
            path: {
                'therapistId': therapistId,
            },
        });
    }
    /**
     * Updates the settings of a therapist
     * Update Settings
     * @param therapistId
     * @param requestBody
     * @returns TherapistSettings
     * @throws ApiError
     */
    public updateSettings(
        therapistId: number,
        requestBody: TherapistSettings,
    ): CancelablePromise<TherapistSettings> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/therapists/{therapistId}/settings',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
