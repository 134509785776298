/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MinimalUser } from '../models/MinimalUser';
import type { RequestEmailChangePayload } from '../models/RequestEmailChangePayload';
import type { ToggleUserNotification } from '../models/ToggleUserNotification';
import type { UpdatePasswordPayload } from '../models/UpdatePasswordPayload';
import type { UpdateUserPayload } from '../models/UpdateUserPayload';
import type { User } from '../models/User';
import type { UserNotificationSetting } from '../models/UserNotificationSetting';
import type { UserProfilePictureUploadResponse } from '../models/UserProfilePictureUploadResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UsersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get user
     * Get user
     * @param userId
     * @returns User
     * @throws ApiError
     */
    public getUser(
        userId: number,
    ): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{userId}',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * Update user
     * Update user
     * @param userId
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public updateUser(
        userId: number,
        requestBody: UpdateUserPayload,
    ): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/{userId}',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete user
     * Delete user
     * @param userId
     * @returns any
     * @throws ApiError
     */
    public deleteUser(
        userId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/{userId}',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * Upload profile picture
     * Upload profile picture
     * @param userId
     * @param formData
     * @returns UserProfilePictureUploadResponse
     * @throws ApiError
     */
    public uploadProfilePicture(
        userId: number,
        formData: {
            file?: Blob;
        },
    ): CancelablePromise<UserProfilePictureUploadResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/{userId}/upload-profile-picture',
            path: {
                'userId': userId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Request Email Change
     * Request an email with a link to change the email address
     * @param userId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public requestEmailChange(
        userId: number,
        requestBody: RequestEmailChangePayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/{userId}/request-email-change',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Request Password Reset
     * Request an email with a link to reset the password
     * @param userId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public changePassword(
        userId: number,
        requestBody: UpdatePasswordPayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/{userId}/change-password',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all notification settings
     * Get all notification settings
     * @param userId
     * @returns UserNotificationSetting
     * @throws ApiError
     */
    public getSettings(
        userId: number,
    ): CancelablePromise<Array<UserNotificationSetting>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{userId}/notifications/settings',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * Toggle notification setting
     * Toggle notification setting
     * @param userId
     * @param notification
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public toggleSetting(
        userId: number,
        notification: string,
        requestBody: ToggleUserNotification,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/{userId}/notifications/settings/{notification}',
            path: {
                'userId': userId,
                'notification': notification,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get user
     * Get users
     * @param therapistId
     * @returns MinimalUser
     * @throws ApiError
     */
    public getUsersForTherapist(
        therapistId: number,
    ): CancelablePromise<Array<MinimalUser>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/users',
            path: {
                'therapistId': therapistId,
            },
        });
    }
}
