/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Feature } from '../models/Feature';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FeaturesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get features
     * Get features
     * @returns Feature
     * @throws ApiError
     */
    public getFeatures(): CancelablePromise<Array<Feature>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/features',
        });
    }
    /**
     * Get feature
     * Get feature
     * @param featureId
     * @returns Feature
     * @throws ApiError
     */
    public getFeature(
        featureId: number,
    ): CancelablePromise<Feature> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/features/{featureId}',
            path: {
                'featureId': featureId,
            },
        });
    }
    /**
     * Get feature by slug
     * Get feature by slug
     * @param slug
     * @returns Feature
     * @throws ApiError
     */
    public getFeatureBySlug(
        slug: string,
    ): CancelablePromise<Feature> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/features/slug/{slug}',
            path: {
                'slug': slug,
            },
        });
    }
}
